import React from "react";

const ButtonCalendar = (props) => {
    return (
        <button
            onClick={props.onClick}
            className="btn-calendar"
            type={props.type}
            {...props}
            style={props.style}
        >
            {props.children}
        </button>
    );
};

export default ButtonCalendar;