import React, { useState, useRef } from "react";
import ButtonEmail from "../button/ButtonEmail";
import emailjs from "@emailjs/browser";
// import Button from "../button/Button";

const GetEmailReminder = (props) => {
  const form = useRef();
  const [hasError, setHasError] = useState(false);
  const [email, setEmail] = useState("");
  const submitHandler = (e) => {
    e.preventDefault();
    if (!email) return;

    emailjs
      .sendForm(
        "service_int68n8",
        "template_ywma7bv",
        form.current,
        "z11QyOaVTBGwPxfuy"
      )
      .then(
        (result) => {
          console.log(result.text);
          if (result.text === "OK") {
            alert("Agendado correctamente :) !");
            setEmail("");
          }
        },
        (error) => {
          alert("No se pudo enviar el correo, perdon :( ");
          setEmail("");
          console.log(error.text);
        }
      );
  };

  const inputClass = `input-container__input ${
    hasError ? "input-container__input--invalid" : ""
  }`;

  return (
    <div
      // id="mauticform_wrapper_au2021anotatealevento"
      className="mauticform_wrapper"
    >
      <form ref={form} onSubmit={submitHandler} className={props.className}>
        <div
          className="mauticform-error"
          id="mauticform_au2021anotatealevento_error"
        ></div>
        <div
          className="mauticform-message"
          id="mauticform_au2021anotatealevento_message"
        ></div>
        <div className="mauticform-innerform ">
          <label className="counter-form__label">AVÍSENME POR EMAIL</label>
          <div
            className="mauticform-page-wrapper mauticform-page-1 counter-form__input-container input-container"
            data-mautic-form-page="1"
          >
            <div
              // id="mauticform_au2021anotatealevento_email"
              data-validate="email"
              data-validation-type="email"
              className="mauticform-row mauticform-email mauticform-field-1 mauticform-required"
            >
              <input
                // id="mauticform_input_au2021anotatealevento_email"
                onChange={(e) => setEmail(e.target.value)}
                // name="mauticform[email]"
                value={email}
                name="email"
                className={inputClass + "mauticform-input"}
                type="email"
              />
            </div>
            {/* <input
              id="mauticform_input_au2021anotatealevento_au2021"
              name="mauticform[au2021]"
              value="Yes"
              className="mauticform-hidden"
              type="hidden"
            /> */}
            <div
              id="mauticform_au2021anotatealevento_submit"
              className="mauticform-row mauticform-button-wrapper mauticform-field-3"
            >
              <ButtonEmail
                type="submit"
                variant="red"
                // name="mauticform[submit]"
                // onClick={submitHandler}
                // id="mauticform_input_au2021anotatealevento_submit"
                // additionalclassname="mauticform-button btn btn-default input-container__submit"
              >
                ENVIAR
              </ButtonEmail>
            </div>
          </div>
        </div>

        {/* <input
          type="hidden"
          name="mauticform[formId]"
          id="mauticform_au2021anotatealevento_id"
          value="5"
        />
        <input
          type="hidden"
          name="mauticform[return]"
          id="mauticform_au2021anotatealevento_return"
          value=""
        />
        <input
          type="hidden"
          name="mauticform[formName]"
          id="mauticform_au2021anotatealevento_name"
          value="au2021anotatealevento"
        /> */}
      </form>
    </div>
  );
};

export default GetEmailReminder;
