import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <h1 className="footerText">
        © 2022 Fratelli Branca Destilerías S.A. Todos los derechos reservados.
        Beber con moderación. Prohibida la venta a menores de 18 años.
       <a href="https://www.branca.com.ar/">www.branca.com.ar</a>
      </h1>
    </div>
  );
};

export default Footer;
