import React from "react";
import Countdown from "react-countdown";
// import Button from "../button/Button";
import ButtonCalendar from "../button/ButtonCalendar";

const Counter = (props) => {
  const endDate = new Date(2022, 8, 15, 18, 59, 59);

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="counter__countdown countdown">
        <div className="countdown__wrapper">
          <div className="countdowm__item">
            <span className="countdown__item-value">{days}</span>
            <span className="countdown__item-label">días</span>
          </div>

          <div className="countdowm__item">
            <span className="countdown__item-value">{hours}</span>
            <span className="countdown__item-label">horas</span>
          </div>

          <div className="countdowm__item">
            <span className="countdown__item-value">{minutes}</span>
            <span className="countdown__item-label">minutos</span>
          </div>

          <div className="countdowm__item">
            <span className="countdown__item-value">{seconds}</span>
            <span className="countdown__item-label">segundos</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={props.className}>
      <p className="counter__title">FALTAN</p>
      <Countdown
        date={endDate}
        renderer={renderer}
        intervalDelay={0}
        precision={1}
      />
      <div className="container__btn">
        {/* <div className="btn__top"></div> */}
        {/* <Button additionalClassName="counter__cta" type="submit">
          AGREGALO A TU CALENDARIO
        </Button> */}
        <ButtonCalendar>
          <a
            style={{ color: "white" }}
            target="_blank"
            href="https://calendar.google.com/event?action=TEMPLATE&tmeid=NzVuZmpxcXQ0Nm52bzYxOGY3MGNwaWk4aWogYXJ0ZXVuaWNvLmJiLm1jQG0&tmsrc=arteunico.bb.mc%40gmail.com"
          >
            AGREGALO A TU CALENDARIO
          </a>
        </ButtonCalendar>
      </div>
    </div>
  );
};

export default Counter;
