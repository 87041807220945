import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid";
import axios from "axios";
import "boxicons";
import Counter from "../counter/Counter";
import GetEmailReminder from "../getEmailReminder/GetEmailReminder";
import shadow1 from "../../assets/images/shadow-1.png";
import shadow2 from "../../assets/images/shadow-2.png";
import shadow3 from "../../assets/images/shadow-3.png";
import shadow4 from "../../assets/images/shadow-4.png";
import shadow5 from "../../assets/images/shadow-5.png";
import shadow6 from "../../assets/images/shadow-6.png";
import shadow7 from "../../assets/images/shadow-7.png";
import shadow8 from "../../assets/images/shadow-8.png";
import shadow9 from "../../assets/images/shadow-9.png";
import shadow10 from "../../assets/images/shadow-10.png";
import shadow11 from "../../assets/images/shadow-11.png";
import shadow12 from "../../assets/images/shadow-12.png";
import shadow15 from "../../assets/images/shadow-15.png";

import logoArteUnico from "../../assets/images/logo2.png";

const CounterBanner = () => {
  const [awards, setAwards] = useState("");
  const [state, setState] = useState({
    countryName: "",
  });

  const changeAwardsByCountry = (country) => {
    switch (country) {
      case "Argentina":
        setAwards("$2.000.000");
        break;
      case "Paraguay":
        setAwards("$39.000.000");
        break;
      case "Uruguay":
        setAwards("$620.000");
        break;
      case "Chile":
        setAwards("$10.000.000");
        break;
    }
  };

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setState({
          ...state,
          countryName: data.country_name,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  useEffect(() => {
    changeAwardsByCountry(state.countryName);
  }, [state.countryName]);

  return (
    <section className="counter-banner">
      <Container>
        <div className="counter-banner__container">
          <Row>
            <div className="counter-banner__wrapper">
              <Col>
                <div className="counter-banner__pictureContainer">
                  <picture>
                    <img src={logoArteUnico} alt="Arte Único Logo" />
                  </picture>

                  <div className="headings-left__contest">
                    <h3>
                      VUELVE EL CONCURSO DE AFICHES MÁS IMPORTANTE DE SUDAMÉRICA
                    </h3>
                  </div>
                  <div className="headings-left__awards">
                    <h3>
                      <span>{awards}</span> EN PREMIOS
                    </h3>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="counter-banner__counter-box counter-box">
                  <div className="counter-banner__counter-box counter-social">
                    <Counter className="counter-box__counter counter" />
                    <GetEmailReminder className="counter-box__counter-form counter-form" />
                  </div>
                  <div className="counter-banner__counter-box container-social">
                    <div className="counter-banner__counter-box social-top"></div>
                    <div className="counter-banner__counter-box social">
                      <div className="icon">
                        <a
                          href="https://youtube.com/c/BrancaArgentina"
                          target="_blank"
                        >
                          <box-icon
                            type="logo"
                            name="youtube"
                            color="white"
                          ></box-icon>
                        </a>
                      </div>
                      <div className="icon">
                        <a
                          href="https://instagram.com/fernetbranca_ar?igshid=ZDg1NjBiNjg="
                          target="_blank"
                        >
                          <box-icon
                            type="logo"
                            name="instagram"
                            color="white"
                          ></box-icon>
                        </a>
                      </div>
                      <div className="icon">
                        <a
                          href="https://www.facebook.com/FernetBrancaAr/"
                          target="_blank"
                        >
                          <box-icon
                            type="logo"
                            name="facebook"
                            color="white"
                          ></box-icon>
                        </a>
                      </div>
                      <div className="icon">
                        <a
                          href="https://twitter.com/fernetbrancaarg?s=21&t=mR5aQO5hyLBy7OspPFbA9g"
                          target="_blank"
                        >
                          <box-icon
                            type="logo"
                            name="twitter"
                            color="white"
                          ></box-icon>
                        </a>
                      </div>
                    </div>
                    <div className="counter-banner__counter-box year">
                      <h3>2022</h3>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </Row>
        </div>
      </Container>
      <div className="shadows__shadow" aria-hidden="true" shadow-id="15">
        <img src={shadow15} alt="Shadow 15" className="shadows__shadow-image" />
      </div>
      <div className="counter-banner__shadows shadows" aria-hidden="true">
        <div className="shadows__shadow" aria-hidden="true" shadow-id="1">
          <img src={shadow1} alt="Shadow 1" className="shadows__shadow-image" />
        </div>
        <div className="shadows__shadow" aria-hidden="true" shadow-id="2">
          <img src={shadow2} alt="Shadow 2" className="shadows__shadow-image" />
        </div>
        <div className="shadows__shadow" aria-hidden="true" shadow-id="3">
          <img src={shadow3} alt="Shadow 3" className="shadows__shadow-image" />
        </div>
        <div className="shadows__shadow" aria-hidden="true" shadow-id="4">
          <img src={shadow4} alt="Shadow 4" className="shadows__shadow-image" />
        </div>
        <div className="shadows__shadow" aria-hidden="true" shadow-id="5">
          <img src={shadow5} alt="Shadow 5" className="shadows__shadow-image" />
        </div>
        <div className="shadows__shadow" aria-hidden="true" shadow-id="6">
          <img src={shadow6} alt="Shadow 6" className="shadows__shadow-image" />
        </div>
        <div className="shadows__shadow" aria-hidden="true" shadow-id="7">
          <img src={shadow7} alt="Shadow 7" className="shadows__shadow-image" />
        </div>
        <div className="shadows__shadow" aria-hidden="true" shadow-id="8">
          <img src={shadow8} alt="Shadow 8" className="shadows__shadow-image" />
        </div>
        <div className="shadows__shadow" aria-hidden="true" shadow-id="9">
          <img src={shadow9} alt="Shadow 9" className="shadows__shadow-image" />
        </div>
        <div className="shadows__shadow" aria-hidden="true" shadow-id="10">
          <img
            src={shadow10}
            alt="Shadow 10"
            className="shadows__shadow-image"
          />
        </div>
        <div className="shadows__shadow" aria-hidden="true" shadow-id="11">
          <img
            src={shadow11}
            alt="Shadow 11"
            className="shadows__shadow-image"
          />
        </div>
        <div className="shadows__shadow" aria-hidden="true" shadow-id="12">
          <img
            src={shadow12}
            alt="Shadow 12"
            className="shadows__shadow-image"
          />
        </div>
        <div className="shadows__shadow" aria-hidden="true" shadow-id="13">
          <img
            src={shadow12}
            alt="Shadow 13"
            className="shadows__shadow-image"
          />
        </div>
      </div>
    </section>
  );
};

export default CounterBanner;
