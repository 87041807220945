import Footer from "./components/footer/Footer.jsx";
import Landing from "./pages/Landing.jsx";

function App() {
  return (
    <div className="App">
      <Landing />
      <Footer/>
    </div>
  );
}

export default App;
