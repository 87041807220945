import React from "react";
import About from "../components/about/About";
import CounterBanner from "../components/counter-banner/CounterBanner";

const Landing = () => {
    return (
        <div className="landing">
            <CounterBanner />
            <About />
            
        </div>
    );
};

export default Landing;
