import React, { useEffect, useState } from "react";
import axios from 'axios';

const About = () => {

  const [awards, setAwards] = useState("");
  const [state, setState] = useState({
    countryName: "",
  });

  const changeAwardsByCountry = (country) => {
    switch (country) {
      case "Argentina":
        setAwards("$2.000.000");
        break;
      case "Paraguay":
        setAwards("$39.000.000");
        break;
      case "Uruguay":
        setAwards("$620.000");
        break;
      case "Chile":
        setAwards("$10.000.000");
        break;
    }
  };

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setState({
          ...state,
          countryName: data.country_name,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  useEffect(() => {
    changeAwardsByCountry(state.countryName);
  }, [state.countryName]);




  return (
    <section name="about" className="about">
      <div className="about__wrapper">
        <div className="about__content">
          <h2 className="about__headline">
            <span><p className="about__question">¿</p>QUÉ ES</span>
            <br />
            <span>ARTE ÚNICO?</span>
          </h2>
          <p className="about__text">
            ARTE ÚNICO ES UNO DE LOS CONCURSOS DE ARTE Y DISEÑO MÁS IMPORTANTES
            DE LATINOAMÉRICA. UN ESPACIO EN EL QUE ARTISTAS MAYORES DE 18 AÑOS
            DE ARGENTINA, CHILE, URUGUAY Y PARAGUAY PUEDEN MOSTRAR Y POTENCIAR
            SU CREATIVIDAD. <br /> <br /> EN ESTA 15a EDICIÓN QUEREMOS QUE
            DEMUESTRES POR QUÉ EL ARTE CONECTA AL MUNDO.
          </p>
        </div>
        <div className="about__info info">
          <p className="info__item info__item--blue">
            <span className="color-blue-100">14</span>AÑOS
          </p>
          <p className="info__item info__item--blue">
            <span className="color-blue-100">2</span> CATEGORÍAS
          </p>
          <p className="info__item info__item--blue">
            <span className="color-blue-100">{awards}</span>EN PREMIOS
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
